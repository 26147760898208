import React from 'react'
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  from,
  ApolloLink,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'
import { useFirebaseAuth, parseSeverityFromGqlErrors } from '@flock/utils'
import * as Sentry from '@sentry/gatsby'
// @ts-ignore
import { Severity } from '@flock/utils/src/__generated__/protobuf/ts/errors/errors'
import {
  SHARE_PROPERTY_QUESTIONNAIRE_URL,
  SHARE_PROPERTY_QUESTIONNAIRE_V3_URL,
  SHARE_PERSONAL_INFO_V3_URL,
  SHARE_MORTGAGE_V3_URL,
} from '../constants'

type ConfiguredApolloProviderProps = {
  children: React.ReactNode
}

const CriticalOperationNames = [
  'GetPortfolio',
  'GetUpdates',
  'GetDocuments',
  'GetDocumentDownloadUrl',
  'GetOrdersByLegalEntity',
  'GetLegalEntity',
  'GetLegalEntitiesHoldings',
  'GetLegalEntitiesDistributions',
]

const ConfiguredApolloProvider = ({
  children,
}: ConfiguredApolloProviderProps) => {
  const apolloLinks = []

  const { user } = useFirebaseAuth()

  const errorLink = onError((errResult) => {
    const { operation, graphQLErrors, networkError } = errResult
    const context = operation.getContext()
    const traceId = context.response?.headers?.get('X-Amzn-Trace-Id')
    const critical = CriticalOperationNames.includes(
      operation.operationName
    ).toString()

    const severity = parseSeverityFromGqlErrors(graphQLErrors)
    if (severity !== Severity.SEVERITY_IGNORE) {
      const error = new Error(
        `GQL Operation failed: ${operation.operationName}`
      )
      Sentry.captureException(error, {
        fingerprint: ['gql-error', operation.operationName, error.message],
        tags: {
          traceId,
          critical,
          operationName: operation.operationName,
        },
        extra: {
          variables: JSON.stringify(operation.variables, null, 2),
          graphQLErrors: JSON.stringify(graphQLErrors, null, 2),
          networkError: JSON.stringify(networkError, null, 2),
        },
      })
    }
  })
  apolloLinks.push(errorLink)

  const authorizationLink = setContext(async (_, previousContext) => {
    // If this token will expire in less than an hour, refresh the token
    const token = await user?.getIdToken()
    return {
      headers: {
        ...previousContext.headers,
        Authorization: `Bearer ${token}`,
      },
    }
  })

  const oneTimeLinkPaths = [
    SHARE_PROPERTY_QUESTIONNAIRE_URL,
    SHARE_PROPERTY_QUESTIONNAIRE_V3_URL,
    SHARE_PERSONAL_INFO_V3_URL,
    SHARE_MORTGAGE_V3_URL,
  ]

  if (
    typeof window !== 'undefined' &&
    oneTimeLinkPaths.every((path) => !window.location.pathname.includes(path))
  ) {
    apolloLinks.push(authorizationLink)
  }
  const uploadLink = createUploadLink({ uri: process.env.GATSBY_APOLLO_URL })
  apolloLinks.push(uploadLink)

  const client = new ApolloClient({
    link: from(apolloLinks as ApolloLink[]),
    cache: new InMemoryCache(),
  })

  return (
    <>
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </>
  )
}

export default ConfiguredApolloProvider
